var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type != "button"
    ? _c(
        "div",
        {
          staticClass: "fav-element",
          attrs: { title: _vm.title, tabindex: "0" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleFav($event)
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.toggleFav($event)
            }
          }
        },
        [
          _c("i", {
            class: {
              "fa  fa-heart-o": _vm.isFav(),
              "fa fa-heart": !_vm.isFav()
            },
            attrs: { "aria-hidden": "true" }
          })
        ]
      )
    : _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.toggleFav } },
        [
          _c("i", {
            class: {
              "fa  fa-heart-o ": _vm.isFav(),
              "fa fa-heart": !_vm.isFav()
            },
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFav(),
                  expression: "!isFav()"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.voMsg("tpl.text.addFavLabel")))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFav(),
                  expression: "isFav()"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.voMsg("tpl.text.removeFavLabel")))]
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }